import React from "react";

function ClaimNFT({
  claimed,
  canClaim,
  wallet,
  handleClaim,
  handleConnectWallet,
}) {
  return (
    <div>
      <div className=" bg-[#d9d9d9] mx-auto md:min-w-0 w-[300px] mt-8 sm:w-[350px]">
        <main class="flex items-center">
          <div class="">
            <div class="lg:grid lg:grid-cols-12 lg:auto-rows-min lg:gap-x-8">
              <div class="lg:col-span-12">
                <section class="flex flex-col gap-2 px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5">
                  <dl class="space-y-4">
                    
                    <div class="flex items-center justify-between gap-4">
                      <dt className="flex flex-col gap-1 text-sm text-gray-600">
                        <span>Reward rate</span>
                        <small class="text-xs flex-shrink-0 text-gray-400">
                          How many tokens you receive{" "}
                          <div class="inline">every 1 day</div>
                        </small>
                      </dt>
                      <dd class="text-sm font-medium text-gray-900">
                        <div className="text-xs sm:text-[base] min-w-[50px]">
                          5 DOOP
                        </div>
                      </dd>
                    </div>
                    <div class="border-t border-[#f1f1f1] pt-4 flex items-center justify-between gap-4">
                      <dt class="flex flex-col gap-1 text-sm text-gray-600">
                        <span>Claim window</span>
                        <small class="text-xs flex-shrink-0 text-gray-400">
                          How often can you claim
                        </small>
                      </dt>
                      <dd class="text-sm font-medium text-gray-900">
                        <div>every 1 day</div>
                      </dd>
                    </div>
                    <div class="border-t border-[#f1f1f1] pt-4 flex items-center justify-between gap-4">
                      <dt class="text-base font-medium text-gray-900">
                        Claimable now for you
                      </dt>
                      <dd class="text-base font-medium text-gray-900">
                        <div className="text-xs sm:text-sm text-bold min-w-[80px]">
                          {canClaim} DOOP
                        </div>
                      </dd>
                    </div>
                  </dl>
                  <div class="flex flex-col justify-center items-center">
                    {/* <button class="mt-4 w-full bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed">
                      Connect to claim
                    </button> */}
                    {
                      // typeof window.ethereum !== "undefined" ? (
                      wallet?.address ? (
                        <button
                          onClick={handleClaim}
                          class="mt-4 w-full bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          {/* {wallet?.address?.slice(0, 5)}...{wallet?.address?.slice(-4)} */}
                          Claim
                        </button>
                      ) : (
                        <button
                          onClick={handleConnectWallet}
                          class="mt-4 w-full bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          Connect To Claim
                        </button>
                      )
                      // ) : (
                      //   <a
                      //     target="_blank"
                      //     href="https://metamask.io/download/"
                      //     class="mt-4 w-full bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                      //     rel="noreferrer"
                      //   >
                      //     Install Metamask
                      //   </a>
                      // )
                    }
                  </div>
                  <div class="flex flex-col gap-2"></div>
                </section>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ClaimNFT;
