import React from "react";

export default function BuyNFT({ newMint, context }) {
  // const data = [
  //   { name: "W1", price: "5", url: "./assets/w1.png" },
  //   { name: "W2", price: "5", url: "./assets/w2.png" },
  //   { name: "W3", price: "5", url: "./assets/w3.png" },
  //   { name: "W4", price: "5", url: "./assets/w4.png" },
  //   { name: "W5", price: "5", url: "./assets/w5.png" },
  //   { name: "W6", price: "5", url: "./assets/w6.png" },
  //   { name: "W7", price: "5", url: "./assets/w7.png" },
  //   { name: "W8", price: "5", url: "./assets/w8.png" },
  //   { name: "W9", price: "5", url: "./assets/w9.png" },
  //   { name: "W10", price: "5", url: "./assets/w10.png" },
  //   { name: "W11", price: "5", url: "./assets/w11.png" },
  //   { name: "W12", price: "5", url: "./assets/w12.png" },
  // ];
  return (
    <>
      <div className="flex px-4 justify-evenly">
        <div class="max-w-screen-xl  mx-auto  sm:p-10 md:p-16">
          <div class="grid grid-cols-1 lg:grid-cols-4  md:grid-cols-2 gap-10">
            {context.products.map((item, index) => (
              // <div
              //   key={item.name}
              //   class="rounded overflow-hidden shadow-lg max-h-full "
              // >
              //   <div class="relative bg-gray-900">
              //     {/* <div className="h-[230px] py-8"> */}

              //     <img
              //       class="w-[80%] h-[200px] pt-[60px] pb-[20px] block mx-auto "
              //       src={item.url}
              //       // src="https://images.pexels.com/photos/196667/pexels-photo-196667.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
              //       alt="Sunset in the mountains"
              //     />
              //     {/* </div> */}

              //     {/* <div class="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-16"></div> */}

              //     <div class="text-xs absolute top-0 right-0 bg-slate-800 px-4 text-white rounded-full h-12 w-12 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
              //       <span class="font-bold">{item.price}</span>
              //       {/* <small>Left</small> */}
              //     </div>
              //   </div>

              //   <div class="px-6 py-4 max-h-[50px] bg-slate-800 hover:bg-white text-white hover:text-indigo-600 hover:font-bold transition duration-500 ease-in-out  ">
              //     {/* <a
              //     href="#"
              //     class="font-semibold text-lg inline-block hover:text-indigo-600 transition duration-500 ease-in-out"
              //   >
              //     Best View
              //   </a> */}
              //     <p
              //       class=" text-sm text-center    cursor-pointer"
              //       onClick={() => newMint(index + 1)}
              //     >
              //       MINT
              //     </p>
              //   </div>
              // </div>

              <div class=" w-[260px] h-[260px]  overflow-hidden max-h-full max-w-sm bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                <img
                  class=" block mx-auto w-[100%] h-[200px] transition duration-500 hover:scale-90"
                  src={item.url} // src="/docs/images/products/apple-watch.png"
                  alt="product image"
                />

                <div class="px-5 pb-5 mt-4">
                  <div class="flex gap-6 justify-between items-center">
                    <span class="text-sm font-bold text-gray-900 dark:text-white">
                      {item.price} DOOP
                    </span>
                    <div
                      onClick={context.addProductToCart.bind(this, item)}
                      class="text-white bg-[#9B8579]    focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-5min-h-[120px] min-w-[100px] py-2.5 text-center dark:bg-[#9B8579]   dark:hover:bg-[#967C4C]  dark:focus:ring-indigo-800 cursor-pointer"
                    >
                      Add to cart{" "}
                      {context.cart.map((item) =>
                        item.id == index + 1 ? (
                          <strong>({item.quantity})</strong>
                        ) : (
                          ""
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
