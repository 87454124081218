import { useState, useEffect } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import { ethers } from "ethers";
// import Minter from "./components/Minter";
// import Navbar from "./components/Navbar";
import { abi, NFTAbi } from "./contract/contractAbi";
import { address, NFTaddress } from "./contract/contractAddress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Web3Modal from "web3modal";
import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import BuyNFT from "./components/BuyNFT";
import ClaimNFT from "./components/ClaimNFT";
import Cart from "./components/Cart";
import { HiOutlineShoppingCart } from "react-icons/hi";
// import { providerOptions } from "./providerOptions";
import ShopContext from "./context/shop-context";
import CartPage from "./components/CartComp";
import { initializeProvider } from "@metamask/providers";

const providerOptions = {
  walletlink: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: "WoopDoop", // Required
      infuraId: "219cce269f774a9f9970dd42da83cb5b", // Required unless you provide a JSON RPC url; see `rpc` below
      // infuraId: process.env.INFURA_KEY, // Required unless you provide a JSON RPC url; see `rpc` below
    },
  },
  walletconnect: {
    package: WalletConnect, // required
    options: {
      infuraId: "219cce269f774a9f9970dd42da83cb5b", // required
      // infuraId: process.env.INFURA_KEY, // required
    },
  },
};
const web3Modal = new Web3Modal({
  // cacheProvider: false, // optional
  disableInjectedProvider: false,
  providerOptions, // required
});
function App() {
  const [wallet, setWallet] = useState({});
  const [canClaim, setCanClaim] = useState(0);
  const [claimed, setClaimed] = useState(0);
  const [claimStatus, setClaimStatus] = useState(false);
  const [open, setOpen] = useState(false);

  const location = useLocation();
  // console.log(location.pathname);

  // let web3Modal;
  const handleConnectWallet = async () => {
    // try {
    //   let web3Modal = new Web3Modal({
    //     cacheProvider: false,
    //     providerOptions,
    //   });
    //   const web3ModalInstance = await web3Modal.connect();
    //   const web3ModalProvider = new ethers.providers.Web3Provider(
    //     web3ModalInstance
    //   );
    //   const signer = web3ModalProvider.getSigner();
    //   console.log(web3ModalProvider);
    //   setWallet({
    //     ...wallet,
    //     address: accounts?.result[0],
    //     signer: signer,
    //     network: await _signer.getNetwork(),
    //   });
    // } catch (error) {}
    // if (typeof window.ethereum !== "undefined") {

    try {
      //   // await window.ethereum.enable();
      //   web3Modal = new Web3Modal({
      //     theme: "dark",
      //     // network: "mainnet",
      //     cacheProvider: true,
      //     // cacheProvider: false,
      //     // disableInjectedProvider: true,
      //     providerOptions,
      //   });
      // if()
      const web3ModalInstance = await web3Modal.connect();

      const web3ModalProvider = new ethers.providers.Web3Provider(
        web3ModalInstance
      );
      const account = await web3ModalProvider.listAccounts();
      // const account = web3ModalProvider.provider._addresses[0];
      // const accounts = await window.ethereum.send("eth_requestAccounts");
      console.log(account);
      const _signer = await web3ModalProvider.getSigner();
      console.log("hello", web3ModalProvider);
      setWallet({
        ...wallet,
        address: account[0],
        signer: _signer,
        network: web3ModalProvider._network,
      });
      // const accounts = await window.ethereum.send("eth_requestAccounts");
      // const _signer = new ethers.providers.Web3Provider(window.ethereum);
      // setWallet({
      //   ...wallet,
      //   address: accounts?.result[0],
      //   signer: _signer.getSigner(),
      //   network: await _signer.getNetwork(),
      // });

      //  contract.canClaim(address).then((res)=>console.log("Helooooo",res))
    } catch (error) {
      console.log("Error:", error.message);
    }
    // } else alert("Please install MetaMask");
  };
  // Switch Network
  // const handleSwitchNetwork = async () => {
  //   if (window.ethereum) {
  //     try {
  //       await window.ethereum.request({
  //         method: "wallet_switchEthereumChain",
  //         params: [{ chainId: "0x4" }],
  //       });
  //     } catch (error) {
  //       if (error.code === 4902) {
  //         alert("Please add this network to metamask!");
  //       }
  //     }
  //   }
  // };
  const Msg = ({ closeToast, toastProps, hash }) => (
    <div className="bg-[white] w-1/2 ">
      <a
        className="text-underline text-gray-400"
        href={`https://etherscan.io/tx/${hash}`}
        target="_blank"
      >
        https://etherscan.io/tx/${hash}
      </a>
      {/* <button>Retry</button> */}
      {/* <button onClick={closeToast}>Close</button> */}
    </div>
  );

  const notify = (hash) =>
    toast(
      <Msg hash={hash} />,

      {
        // position: "bottom-center",

        autoClose: 5000,
        hideProgressBar: true,
        // closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  const handleClaim = async () => {
    setClaimStatus(true);
    // if (typeof window.ethereum !== "undefined") {
    //   try {
    //     console.log("to be coded...");
    //   } catch (error) {
    //     console.log("Error:", error.message);
    //   }
    // } else alert("Please install MetaMask");

    let contract = new ethers.Contract(address, abi, wallet?.signer);
    console.log(contract);
    toast.promise(
      contract.claim().then((transaction) => {
        toast.promise(
          transaction
            .wait()
            .then((tx) => {
              // toast.info(<a>https://etherscan.io/tx/{tx.transactionHash}</a>);
              // console.log("Hiiii", tx.transactionHash);
              notify(tx.transactionHash);
              setClaimStatus(false);
              // setMintingStatus(false);
            })
            .catch((err) => {
              toast.error("Error in Minting Token:", err);
            }),
          {
            pending: "Claiming in Process...",
            success: "Claimed Successfully 👌",
            error: "Promise rejected 🤯",
          }
        );
      }),
      {
        pending: "Waiting to Sign Transaction...",
        success: "Transaction Signed... 👌",
        error: "Transaction Rejected 🤯",
      }
    );
  };
  const newMint = async (arr1, arr2, price) => {
    let priceee = ethers.BigNumber.from(
      ethers.utils.parseEther(price.toString())
    ).toString();
    console.log(price);
    // return;
    setClaimStatus(true);

    // if (typeof window.ethereum !== "undefined") {
    //   try {
    //     console.log("to be coded...");
    //   } catch (error) {
    //     console.log("Error:", error.message);
    //   }
    // } else alert("Please install MetaMask");

    let contract = new ethers.Contract(address, abi, wallet?.signer);
    let NFTContract = new ethers.Contract(NFTaddress, NFTAbi, wallet?.signer);
    // const price= await NFTContract()
    // console.log("BUY NFTT", contract);
    console.log("BUY NFTT", NFTContract);
    // console.log(id);
    // let c = await NFTContract.catagories(id);

    // let price = ethers.BigNumber.from(c.price).toString();
    console.log(wallet?.address, NFTaddress);
    let checkApproval = ethers.BigNumber.from(
      await contract.allowance(wallet?.address, NFTaddress)
    ).toString();
    let checkBalance = ethers.BigNumber.from(
      await contract.balanceOf(wallet?.address)
    ).toString();
    console.log("ALLowance", checkBalance, "       ", price);
    console.log("balllllllll", parseInt(checkBalance) >= parseInt(price));
    if (parseInt(checkBalance) >= parseInt(priceee)) {
      parseInt(checkApproval) < parseInt(priceee)
        ? toast.promise(
            contract.approve(NFTaddress, priceee).then((transaction) => {
              toast.promise(
                transaction
                  .wait()
                  .then((tx) => {
                    // notify(tx.transactionHash);
                    NFTContract.mintCart(arr1, arr2).then((transaction) => {
                      toast.promise(
                        transaction
                          .wait()
                          .then((tx) => {
                            // notify(tx.transactionHash);
                          })
                          .catch((err) => {
                            toast.error("Error in Minting Token:", err);
                          }),
                        {
                          pending: "Minting in Process...",
                          success: "Minting Successfully 👌",
                          error: "Promise rejected 🤯",
                        }
                      );
                    });
                  })
                  .catch((err) => {
                    toast.error("Error in Minting Token:", err);
                  }),
                {
                  pending: "Approval in Process...",
                  success: "Approved Successfully 👌",
                  error: "Promise rejected 🤯",
                }
              );
            }),
            {
              pending: "Waiting to Sign Transaction...",
              success: "Transaction Signed... 👌",
              error: "Transaction Rejected 🤯",
            }
          )
        : toast.promise(
            NFTContract.mintCart(arr1, arr2).then((transaction) => {
              toast.promise(
                transaction
                  .wait()
                  .then((tx) => {
                    // toast.info(<a>https://etherscan.io/tx/{tx.transactionHash}</a>);
                    // console.log("Hiiii", tx.transactionHash);
                    notify(tx.transactionHash);
                    setClaimStatus(false);
                    // setMintingStatus(false);
                  })
                  .catch((err) => {
                    toast.error("Error in Minting Token:", err);
                  }),
                {
                  pending: "Minting in Process...",
                  success: "Minted Successfully 👌",
                  error: "Promise rejected 🤯",
                }
              );
            }),
            {
              pending: "Waiting to Sign Transaction...",
              success: "Transaction Signed... 👌",
              error: "Transaction Rejected 🤯",
            }
          );
    } else {
      toast.error("Don't have enough tokens:");
    }
  };

  // Detect change in Metamask accounts
  // useEffect(() => {
  //   if (window.ethereum) {
  //     window.ethereum.on("chainChanged", () => handleConnectWallet());
  //     window.ethereum.on("accountsChanged", () => handleSwitchNetwork());
  //     // window.ethereum.on('disconnect', (res)=> console.log("disconected"));

  //   }
  // });

  // if(!window.ethereum.isConnected()){
  //   console.log('Disconnected')
  // }else{
  //   console.log('Connected')
  // }

  // Connect wallet on Refresh Page
  useEffect(async () => {
    // if (web3Modal.cachedProvider)
    // console.log("HEllo", localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER"));
    // if ("injected" == localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")) {
    // http://localhost:3000/
    // } else {
    // await this.web3Modal.clearCachedProvider();
    if (web3Modal.cachedProvider) {
      handleConnectWallet();
    } // }
    // if (window.ethereum)
    // console.log("win", window.ethereum.listAccounts());
    // }
    // eslint-disable-next-line
  }, []);
  let contract = new ethers.Contract(address, abi, wallet?.signer);
  // console.log(contract)
  contract
    .canClaim(wallet.address)
    .then((res) => setCanClaim(ethers.BigNumber.from(res).toString()))
    .catch((err) => {
      setCanClaim(0);
      console.log(err.errorArgs[0]);
    });
  contract
    .totalClaimed(wallet.address)
    .then((res) => setClaimed(ethers.BigNumber.from(res).toString()))
    .catch((err) => toast.error(err.errorArgs[0]));

  useEffect(() => {
    async function data() {
      let contract = new ethers.Contract(address, abi, wallet?.signer);
      // console.log(contract)

      await contract
        .canClaim(wallet.address)
        .then((res) => setCanClaim(ethers.BigNumber.from(res).toString()))
        .catch((err) => {
          setCanClaim(0);
          console.log(err.errorArgs[0]);
        });
      await contract
        .totalClaimed(wallet.address)
        .then((res) => setClaimed(ethers.BigNumber.from(res).toString()))
        .catch((err) => toast.error(err.errorArgs[0]));
    }

    data();
    // eslint-disable-next-line
  }, [claimStatus]);
  const [total, setTotal] = useState(0);
  // console.log("Wallet:", wallet);
  return (
    <ShopContext.Consumer>
      {(context) => (
        <>
          {/* <Navbar
        wallet={wallet}
        connectWallet={handleConnectWallet}
        disconnectWallet={handleDisconnectWallet}
      /> */}
          <ToastContainer />

          <div
            className="min-h-screen  px-5 sm:px-0 "
            style={{ background: "url(assets/bg.png)" }}
          >
            <div className="flex justify-between items-center">
              {location.pathname == "/mint" ? (
                <Link to="/">
                  <img
                    src={"assets/logo.png"}
                    alt="Doop Claim"
                    className="h-auto w-40 md:w-40 py-12 md:ml-24 "
                  />
                </Link>
              ) : (
                <img
                  src={"assets/logo.png"}
                  alt="Doop Claim"
                  className="h-auto w-40 md:w-40 py-12 md:ml-24 cursor-pointer"
                />
              )}
              {wallet?.address ? (
                location.pathname == "/mint" ? (
                  <div className="flex gap-4 items-center ">
                    {" "}
                    {/* <Link to="/cart"> */}
                    <HiOutlineShoppingCart
                      onClick={() => setOpen(true)}
                      className=" cursor-pointer hover:text-indigo-600"
                      size={"40px"}
                    />
                    {context.cart.reduce((count, curItem) => {
                      return count + curItem.quantity;
                    }, 0)}
                    {/* </Link> */}
                    <Link
                      to="/"
                      class=" md:mr-24   bg-indigo-600 border border-transparent rounded-md h-[30px]   px-6 md:px-12 py-4 md:py-6 flex items-center justify-center  text-xs sm:text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      Claim $DOOP
                    </Link>
                    <Cart
                      total={total}
                      setTotal={setTotal}
                      open={open}
                      context={context}
                      setOpen={setOpen}
                      newMint={newMint}
                    />
                  </div>
                ) : (
                  <Link
                    to="/mint"
                    class=" md:mr-24   bg-indigo-600 border border-transparent rounded-md h-[30px]   px-6 md:px-12 py-4 md:py-6 flex items-center justify-center  text-xs sm:text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    Mint Weapons
                  </Link>
                )
              ) : (
                <button
                  onClick={() => {
                    handleConnectWallet();
                  }}
                  class=" md:mr-24   bg-indigo-600 border border-transparent rounded-md h-[30px] px-6 md:px-12 py-4 md:py-6 flex items-center justify-center text-xs sm:text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Connect
                </button>
              )}
              {/* <button
            onClick={() => {
              count ? setCount(false) : setCount(true);

              console.log(count);
            }}
            class=" md:mr-24   bg-indigo-600 border border-transparent rounded-md h-[30px]  px-12 py-6 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {!count ? "Buy NFTs" : "Claim Tokens"}
          </button> */}
            </div>
            {/* <div className="w-1/2 mx-auto ">Helo</div> */}
            {/* {!count ? (
          <ClaimNFT
            claimed={claimed}
            canClaim={canClaim}
            wallet={wallet}
            handleClaim={handleClaim}
            handleConnectWallet={handleConnectWallet}
          />
        ) : (
          <BuyNFT
            wallet={wallet}
            // handleClaim={handleClaim}
            handleConnectWallet={handleConnectWallet}
            newMint={newMint}
          />
        )} */}
            <Routes>
              <Route
                path="/"
                element={
                  <ClaimNFT
                    claimed={claimed}
                    canClaim={canClaim}
                    wallet={wallet}
                    handleClaim={handleClaim}
                    handleConnectWallet={handleConnectWallet}
                  />
                }
              />
              <Route
                path="mint"
                element={
                  <BuyNFT
                    context={context}
                    wallet={wallet}
                    // handleClaim={handleClaim}
                    handleConnectWallet={handleConnectWallet}
                    newMint={newMint}
                  />
                }
              />
              {/* </Routes> */}
              <Route
                path="cart"
                element={
                  <CartPage
                  // context={context}
                  // wallet={wallet}
                  // handleClaim={handleClaim}
                  // handleConnectWallet={handleConnectWallet}
                  // newMint={newMint}
                  />
                }
              />
            </Routes>
          </div>

          {/* <Routes>
        <Route path="/" element={<Minter wallet={wallet} />} />
      </Routes> */}
        </>
      )}
    </ShopContext.Consumer>
  );
}

export default App;
