export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
// export const SUB_PRODUCT = "SUB_PRODUCT";

const addProductToCart = (product, state) => {
  const updatedCart = [...state.cart];
  const updatedItemIndex = updatedCart.findIndex(
    (item) => item.id === product.id
  );

  if (updatedItemIndex < 0) {
    updatedCart.push({ ...product, quantity: 1 });
  } else {
    const updatedItem = {
      ...updatedCart[updatedItemIndex],
    };
    updatedItem.quantity++;
    updatedCart[updatedItemIndex] = updatedItem;
  }
  return { ...state, cart: updatedCart };
};

const removeProductFromCart = (productId, a, state) => {
  // if (a == 1) {
  //   console.log("1111111111111111111");
  // } else if (a == 0) {
  //   console.log("0000000000000000000");
  // }
  console.log("Removing product with id: " + productId + "aaaaaa=" + a);
  const updatedCart = [...state.cart];
  const updatedItemIndex = updatedCart.findIndex(
    (item) => item.id === productId
  );

  const updatedItem = {
    ...updatedCart[updatedItemIndex],
  };

  a == 1 ? updatedItem.quantity-- : (updatedItem.quantity = 0);
  if (updatedItem.quantity <= 0) {
    updatedCart.splice(updatedItemIndex, 1);
  } else {
    updatedCart[updatedItemIndex] = updatedItem;
  }
  return { ...state, cart: updatedCart };
};
// const subProductFromCart = (productId, state) => {
//   console.log("Removing product with id: " + productId);
//   const updatedCart = [...state.cart];
//   const updatedItemIndex = updatedCart.findIndex(
//     (item) => item.id === productId
//   );

//   const updatedItem = {
//     ...updatedCart[updatedItemIndex],
//   };
//   updatedItem.quantity--;
//   if (updatedItem.quantity <= 0) {
//     updatedCart.splice(updatedItemIndex, 1);
//   } else {
//     updatedCart[updatedItemIndex] = updatedItem;
//   }
//   return { ...state, cart: updatedCart };
// };

export const shopReducer = (state, action) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return addProductToCart(action.product, state);
    // case SUB_PRODUCT:
    //   return subProductFromCart(action.productId, state);
    case REMOVE_PRODUCT:
      return removeProductFromCart(action.productId, action.a, state);
    default:
      return state;
  }
};
