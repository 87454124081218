import React, { useState, useReducer } from "react";

import ShopContext from "./shop-context";
import {
  shopReducer,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  // SUB_PRODUCT,
} from "./reducers";

const GlobalState = (props) => {
  const products = [
    {
      id: 1,
      name: "Bow and Arrow",
      price: "450",
      url: "https://meetnippy.mypinata.cloud/ipfs/Qmct7zvugukN4qxchyMdQzySyU6F6nMAwDSekLm6APckXM/37.png   ",
    },
    {
      id: 2,
      name: "Butchers Knife",
      price: "200",
      url: "https://meetnippy.mypinata.cloud/ipfs/Qmct7zvugukN4qxchyMdQzySyU6F6nMAwDSekLm6APckXM/125.png   ",
    },
    {
      id: 3,
      name: "Club",
      price: "100",
      url: "https://meetnippy.mypinata.cloud/ipfs/Qmct7zvugukN4qxchyMdQzySyU6F6nMAwDSekLm6APckXM/54.png   ",
    },
    {
      id: 4,
      name: "Cutlass Sword",
      price: "1500",
      url: "https://meetnippy.mypinata.cloud/ipfs/Qmct7zvugukN4qxchyMdQzySyU6F6nMAwDSekLm6APckXM/75.png   ",
    },
    {
      id: 5,
      name: "Doop Ray",
      price: "10000",
      url: "https://meetnippy.mypinata.cloud/ipfs/Qmct7zvugukN4qxchyMdQzySyU6F6nMAwDSekLm6APckXM/105.png   ",
    },
    {
      id: 6,
      name: "Doop Saber",
      price: "7500",
      url: "https://meetnippy.mypinata.cloud/ipfs/Qmct7zvugukN4qxchyMdQzySyU6F6nMAwDSekLm6APckXM/137.png   ",
    },
    {
      id: 7,
      name: "Knuckles Only",
      price: "75",
      url: "https://meetnippy.mypinata.cloud/ipfs/Qmct7zvugukN4qxchyMdQzySyU6F6nMAwDSekLm6APckXM/17.png   ",
    },
    {
      id: 8,
      name: "Labrys",
      price: "800",
      url: "https://meetnippy.mypinata.cloud/ipfs/Qmct7zvugukN4qxchyMdQzySyU6F6nMAwDSekLm6APckXM/8.png   ",
    },
    {
      id: 9,
      name: "Morning Star Club",
      price: "800",
      url: "https://meetnippy.mypinata.cloud/ipfs/Qmct7zvugukN4qxchyMdQzySyU6F6nMAwDSekLm6APckXM/18.png   ",
    },
    {
      id: 10,
      name: "Nunchuks",
      price: "250",
      url: "https://meetnippy.mypinata.cloud/ipfs/Qmct7zvugukN4qxchyMdQzySyU6F6nMAwDSekLm6APckXM/142.png   ",
    },
    {
      id: 11,
      name: "Samurai Sword",
      price: "2000",
      url: "https://meetnippy.mypinata.cloud/ipfs/Qmct7zvugukN4qxchyMdQzySyU6F6nMAwDSekLm6APckXM/88.png   ",
    },
    {
      id: 12,
      name: "Shuriken",
      price: "50",
      url: "https://meetnippy.mypinata.cloud/ipfs/Qmct7zvugukN4qxchyMdQzySyU6F6nMAwDSekLm6APckXM/76.png   ",
    },

    // { id: 1, name: "W1", price: "5", url: "./assets/w1.png" },
    // { id: 2, name: "W2", price: "5", url: "./assets/w2.png" },
    // { id: 3, name: "W3", price: "5", url: "./assets/w3.png" },
    // { id: 4, name: "W4", price: "5", url: "./assets/w4.png" },
    // { id: 5, name: "W5", price: "5", url: "./assets/w5.png" },
    // { id: 6, name: "W6", price: "5", url: "./assets/w6.png" },
    // { id: 7, name: "W7", price: "5", url: "./assets/w7.png" },
    // { id: 8, name: "W8", price: "5", url: "./assets/w8.png" },
    // { id: 9, name: "W9", price: "5", url: "./assets/w9.png" },
    // { id: 10, name: "W10", price: "5", url: "./assets/w10.png" },
    // { id: 11, name: "W11", price: "5", url: "./assets/w11.png" },
    // { id: 12, name: "W12", price: "5", url: "./assets/w12.png" },
  ];
  // const [cart, setCart] = useState([]);
  const [cartState, dispatch] = useReducer(shopReducer, { cart: [] });
  let total1 = cartState.cart.map((price) =>
    parseInt(price.price * price.quantity)
  );
  let total = total1.reduce((a, b) => a + b, 0);
  let mintIds = cartState.cart.map((price) => price.id);
  let mintQuantity = cartState.cart.map((price) => price.quantity);
  const clearCart = (b) => {
    removeProductFromCart(b, 0);
  };
  const addProductToCart = (product) => {
    setTimeout(() => {
      // setCart(updatedCart);
      // total += 30;
      // total += parseInt(product.price * product.quantity);
      dispatch({ type: ADD_PRODUCT, product: product });
    }, 700);
  };
  // const subProductToCart = (product) => {
  //   setTimeout(() => {
  //     // setCart(updatedCart);
  //     // total += 30;
  //     // total += parseInt(product.price * product.quantity);
  //     dispatch({ type: SUB_PRODUCT, product: product });
  //   }, 700);
  // };

  const removeProductFromCart = (productId, a) => {
    setTimeout(() => {
      // setCart(updatedCart);
      // total -= parseInt(product.price * product.quantity);

      dispatch({ type: REMOVE_PRODUCT, productId: productId, a: a });
    }, 700);
  };

  return (
    <ShopContext.Provider
      value={{
        products: products,
        cart: cartState.cart,
        total: total,
        mintIds: mintIds,
        mintQuantity: mintQuantity,
        addProductToCart: addProductToCart,
        removeProductFromCart: removeProductFromCart,
        clearCart: clearCart,
        // subProductToCart: subProductToCart,
      }}
    >
      {props.children}
    </ShopContext.Provider>
  );
};

export default GlobalState;
